import React, { useState } from "react";
import { Container, Card } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ThreeDots } from 'react-loader-spinner';
import UseAddToCartHook from "../../componets/UseAddToCartHook";
import AddToCartAPI from '../../componets/AddToCartAPI';

const CategoryItems = (categoryItems) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: `(max-width: 992px)` });
  const { cartClick, cartDetail, addToCart, setCartClick } = UseAddToCartHook();
  const [isLoading, setIsLoading] = useState(false);

  const handleAddToCart = (itemId) => {
    setIsLoading(true);
    addToCart(itemId);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1192 },
      items: 6,
      partialVisibilityGutter: 0,
      arrows: false
    },
    desktop: {
      breakpoint: { max: 1191, min: 992 },
      items: 6,
      partialVisibilityGutter: 30,
      arrows: false
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: 4,
      partialVisibilityGutter: 20,
      arrows: false
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 2,
      partialVisibilityGutter: 10,
      arrows: false
    }
  };

  return (
    <div className="categoryItem maxWidthContainerFluid excitingSection pb-3">
      {categoryItems.categoryItems.map((each) => (
        <Container
          fluid
          className="maxWidthContainerFluid paddingContainerFluid categoryPage pt-2"
          key={each.subcategory_id}
          style={{ marginTop: "10px" }}
        >
          <div className="d-flex justify-content-between pb-2">
            <div
              className={`${isMobile ? "divSmallTitle" : "divMediumTitle"} font-md-sm fw-bold font-syncopate`}
            >{` ${each.subcategory_name}`}</div>
            <NavLink to={`/products-category/${each.url}`}>
              <div className="view_all" style={{ color: "#191B1C" }}>
                {t("global.viewAll")}
              </div>
            </NavLink>
          </div>
          <Carousel 
            responsive={responsive}
            infinite={true}
            partialVisible={true}
            containerClass="carousel-container"
            itemClass="carousel-item-padding-40-px"
          >
            {each.items.slice(0,6).map((item, i) => (
              <div key={i} style={{padding: '4px'}}>
                <NavLink to={`/details/${item.url}/${item.sku}`} className="text-decoration-none">
                  <Card className="commoncategoryItems" style={{
                    background: '#FFFFFF',
                    borderRadius: '8px',
                    border: 'none',
                    overflow: 'hidden',
                    height: '100%',
                    width: '100%',
                    margin: '0 auto',
                    position: 'relative'
                  }}>
                    <div style={{
                      position: 'relative',
                      width: '100%',
                      paddingBottom: '100%',
                    }}>
                      <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundImage: `url(${item.image.replaceAll(" ", "%20")})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: '#FFFFFF'
                      }}></div>
                    </div>
                    <div style={{padding: '8px'}}>
                      <div style={{
                        fontSize: '16px',
                        fontWeight: '600',
                        color: '#191B1C',
                        marginBottom: '4px',
                        minHeight: '32px',
                        display: '-webkit-box',
                        WebkitLineClamp: '2',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden'
                      }}>
                        {item.name}
                      </div>
                      <div>
                        <div style={{
                          fontSize: '16px',
                          fontWeight: '600',
                          color: '#191B1C',
                          whiteSpace: 'nowrap',
                          marginBottom: '2px'
                        }}>
                          <span style={{fontSize: '14px', marginRight: '2px', fontWeight: '500'}}>QAR</span>
                          {item.display_price.slice(4)}
                        </div>
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '4px',
                          flexWrap: 'nowrap'
                        }}>
                          <span style={{
                            fontSize: '16px',
                            color: '#666',
                            textDecoration: 'line-through',
                            whiteSpace: 'nowrap'
                          }}>
                            {item.old_price.replace("QAR ", "")}
                          </span>
                          {item.percentage && (
                            <span style={{
                              fontSize: '15px',
                              fontWeight: '600',
                              color: '#4CAF50',
                              backgroundColor: '#E8F5E9',
                              padding: '1px 4px',
                              borderRadius: '4px',
                              whiteSpace: 'nowrap'
                            }}>
                              {item.percentage}% {t("global.off")}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="AddToCartHover d-none d-lg-block">
                      <div 
                        className="AddToCartButton"
                        onClick={(e) => {
                          e.preventDefault();
                          handleAddToCart(item.id);
                        }}
                        style={{
                          textDecoration: "none",
                          color: "#ffffff",
                          textTransform: "uppercase",
                          cursor: "pointer",
                        }}
                      >
                        <div style={{ display:'flex', justifyContent: 'center', alignItems: 'center' }}>
                          {isLoading ? (
                            <ThreeDots type="ThreeDots" color="#00BFFF" height={44} width={40} />
                          ) : (
                            <div>{t("global.addCart")}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Card>
                </NavLink>
              </div>
            ))}
          </Carousel>
        </Container>
      ))}
      {cartClick && <AddToCartAPI cartDetail={cartDetail} setCartClick={setCartClick} cartClick={cartClick}/>}
    </div>
  );
};

export default CategoryItems;